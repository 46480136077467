import { Controller } from "stimulus";
import Rails from "@rails/ujs";
export default class extends Controller {
  static targets = ["errorExplanation", "cardNumberDummy", "cardNumber", "cardExpire", "cardSecurityCode", "cardToken", "form", "closeButton"];

  connect() {
    this.element.addEventListener("ajax:error", (event) => {
      const messages = event.detail[0];

      if(messages !== "null" && messages !== "[]"){
        document.getElementById('flash-block').innerHTML += this.alertMessage(messages);
      }

      setTimeout(() => {
        $(".alert").alert('close')
      },5000);
    });

    this.element.addEventListener("ajax:success", (event) => {
      document.getElementById('flash-block').innerHTML += this.noticeMessage("登録に成功しました。");
    });
  }

  change(event) {
    Rails.fire(this.element, 'submit');
  }

  noticeMessage(message) {
    return  `
    <div class="alert alert-dismissible mx-2 mx-sm-4 mx-lg-5 mt-3 fade show alert-success">
      <button aria-label="Close" class="close" data-dismiss="alert">
        <span aria-hidden="true">×</span>
      </button>
      <div class="h5 m-0"><div class="text-center word-break-word">${message}</div></div>
    </div>
    `
  }

  alertMessage(message) {
    return  `
    <div class="alert alert-dismissible mx-2 mx-sm-4 mx-lg-5 mt-3 fade show alert-danger">
      <button aria-label="Close" class="close" data-dismiss="alert">
        <span aria-hidden="true">×</span>
      </button>
      <div class="h5 m-0 text-center"><div>${message}</div></div>
    </div>
    `
  }
}
